<template id="">
  <div>
    <div class="admin">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3>クライント企業一覧</h3>
      <div class="searchf">
        <label for="search_client">企業名</label>
        <input type="text" name="" v-model="search_word" />
        <button type="button" name="button" @click="search_word_fnc">
          検索
        </button>
      </div>
      <div class="search">
        <p @click="search_client('ア', 'オ')">ア</p>
        <p @click="search_client('カ', 'コ')">カ</p>
        <p @click="search_client('サ', 'ソ')">サ</p>
        <p @click="search_client('タ', 'ト')">タ</p>
        <p @click="search_client('ナ', 'ノ')">ナ</p>
        <p @click="search_client('ハ', 'ホ')">ハ</p>
        <p @click="search_client('マ', 'モ')">マ</p>
        <p @click="search_client('ヤ', 'ヨ')">ヤ</p>
        <p @click="search_client('ラ', 'ロ')">ラ</p>
        <p @click="search_client('ワ', 'ン')">ワ</p>
        <p @click="search_client('ア', 'ン', 1)">他</p>
      </div>
      <table class="searcht">
        <thead>
          <tr class="fixed01">
            <th>企業名</th>
            <th>フリガナ</th>
            <th>代表者氏名</th>
            <th>住所</th>
            <th>電話番号</th>
            <th>メールアドレス</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="state == 0">
            <tr
              class="fixed02"
              v-for="li in client_lists"
              :key="li.com_id"
              @click="$router.push('/intelligent-company_info/' + li.com_id)"
            >
              <td>{{ li.com_name }}</td>
              <td>{{ li.com_kana }}</td>
              <td>{{ li.representative_name }}</td>
              <td>{{ li.com_address }}</td>
              <td>{{ li.com_tel }}</td>
              <td>{{ li.email }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
              class="fixed02"
              v-for="li in filterd_lists"
              :key="li.com_id"
              @click="$router.push('/intelligent-company_info/' + li.com_id)"
            >
              <td>{{ li.com_name }}</td>
              <td>{{ li.com_kana }}</td>
              <td>{{ li.representative_name }}</td>
              <td>{{ li.com_address }}</td>
              <td>{{ li.com_tel }}</td>
              <td>{{ li.email }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <button
      type="button"
      class="uk-button uk-button-secondary uk-width-1-1"
      @click="change_page(0)"
    >
      &lt;&nbsp;戻る
    </button>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      search_flg: false,
      pre_word: null,
      status: null,
      client_lists: null,
      search_word: "",
      filterd_lists: null,
      state: 0,
    };
  },
  mounted() {
    document.title = "クライアント一覧";
  },
  created() {
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      this.get_clients_info();
    }
  },
  methods: {
    change_page(page_num) {
      this.$store.commit("change_page", { page_num: page_num });
    },
    get_clients_info() {
      this.$axios
        .get("/intelligent/get_clients_info")
        .then((response) => {
          if (response.data.flg) {
            this.client_lists = response.data.client_lists;
          } else {
            console.log(
              "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
            );
          }
        })
        .catch((error) => {
          this.state = false;
          console.log(error);
          this.do_something = true;
        });
    },
    search_word_fnc() {
      console.log(this.search_word);
      if (this.search_word == "") {
        this.filterd_lists = null;
        this.state = 0;
      } else {
        this.filterd_lists = this.client_lists.filter((value) => {
          return value.com_name.match(this.search_word);
        });
        this.state = 1;
      }
    },
    search_client(firstChar, lastChar, mode = 0) {
      if (this.pre_word == firstChar && this.state == 2) {
        this.pre_word = "";
        this.state = 0;
      } else {
        let reg;
        if (mode == 0) {
          reg = RegExp("^[" + firstChar + "-" + lastChar + "].*");
        } else {
          reg = RegExp("^[^" + firstChar + "-" + lastChar + "].*");
        }
        this.filterd_lists = this.client_lists.filter((value) =>
          reg.test(value.com_kana)
        );
        this.filterd_lists.sort((a, b) => a.com_kana - b.com_kana);
        this.pre_word = firstChar;
        this.state = 2;
      }
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
  },
};
</script>
<style scoped>
/* 偶数行のスタイル */
table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

/* 奇数行のスタイル */
table tbody tr:nth-child(odd) {
  background-color: white;
}
table tbody tr td {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
p {
  margin-right: 6vw;
  display: inline;
  cursor: pointer;
  color: blue;
}

p:hover {
  background: #d4f0fd;
}

table th,
td {
  width: 300px;
}

table {
  text-align: center;
  width: 100%;
  border-collapse: collapse;
}

table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}

.fixed01 {
  position: sticky;
  top: 0;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}
.fixed02 {
  position: sticky;
  top: 0;
  color: #222222;
  background: #333;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}
</style>
